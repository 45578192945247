<template>
    <v-container fluid>
        <h1>Leads - Add a Lead</h1>
        <v-divider class="my-4"></v-divider>
        The "Add a Lead" feature is not yet available.<br />
        To manually add and properly track our lead, use the guest page and choose the appropriate lead page accordingly.<br />
        - Listing Page (for specific listing inquiry)<br />
        - Contact Us Page (for general inquiry)<br />
        - "Sell | Lease | Appraise" button (for sell/lease or appraisal inquiries)<br />
    </v-container>
</template>

<script>
    export default {
        name: 'LeadsAdd',
    }
</script>
